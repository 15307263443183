.hubNode, .refNode, .xrefNode, .linkNode, .xlinkdelta, .xlinkfull, .satNode, .lsatNode {
  padding: 0.125rem 0.25rem;
  border: 2px solid;
  border-radius: 0.25rem;
}

.hubNode {
  background-color: #c2e5f799;
  border-color: #84bce6;
  color: #047dbb;
}

.refNode {
  background-color: #FFBCB399;
  border-color: #CA2935;
  color: #CA2935;
}

.xrefNode {
  background-color: #FFBCB399;
  border-color: #CA2935;
  color: #CA2935;
}

.linkNode {
  background-color: #c8e9ae99;
  border-color: #85bc55;
  color: #7bb746;
}

.xlinkdelta {
  background-color: #F2F5C899;
  border-color: #85bc55;
  color: #7bb746;
}

.xlinkfull {
  background-color: #E8E8A699;
  border-color: #85bc55;
  color: #7bb746;
}

.satNode {
  background-color: #ffedab99;
  border-color: #fcc535;
  color: #f19d32;
}

.lsatNode {
  background-color: #ffedab99;
  border-color: #fcc535;
  color: #f19d32;
}

.react-flow__node-xrefnode .react-flow__handle {
  background: red;
}

.react-flow__node.selected.selectable > div {
  outline: 4px solid currentcolor;
  outline-offset: 2px;
}
